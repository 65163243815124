<template>
  <DsPageLayout :theme="theme">
    <!-- <template #cookie-bar>
        <PageCookieBar />
      </template> -->
    <template #header="{ headerTheme }">
      <LayoutPageHeader
        v-if="navigationData"
        :header-theme="headerTheme"
        :logo-label="LOGO_LABEL_INVESTOR_RELATIONS"
        :navigation-data="navigationData"
        hide-search
        logo-as-nuxt-link
      />
      <div
        id="portal-header"
        ref="headerPortal"
        class="header-portal"
        @change="handleHeaderPortalUpdate($event, headerPortal)"
      />
    </template>
    <template #content>
      <div
        :style="{ paddingTop: `${headerPortalHeight}px` }"
        class="ds-width-full"
      >
        <slot />
      </div>
    </template>
    <template #footer>
      <LayoutPageFooter
        v-if="footerNavigationData"
        :footer-data="footerNavigationData"
      />
    </template>
    <template #overlay>
      <div id="portal-overlay" />
    </template>
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-once
      v-html="noscriptHtml"
    />
    <!-- eslint-enable vue/no-v-html -->
  </DsPageLayout>
</template>

<script setup>
/**
 * Default Layout
 * will be used on pages where there is no custom layout set
 */

import { DsPageLayout } from 'awds'

const NAVIGATION_TYPE_INVESTOR_RELATIONS = 'investor-relations'
const LOGO_LABEL_INVESTOR_RELATIONS = 'Investor Relations'
// Specificity is because finance slugs need to have white theme, IR will also not be localized/ translated.
const LIGHT_THEME_PAGE = ['financials-slug___en']
const GREY_THEME_PAGES = ['events', 'governance', 'financials']
const DARK_THEME_PAGES = ['index']

const headerPortal = useTemplateRef('headerPortal')

const { handleHeaderPortalUpdate, headerPortalHeight, noscriptHtml } = useLayout()
const route = useRoute()
const { theme } = useTheme()
const { localeGlobalContentData } = useGlobalData()

const navigationData = computed(() => localeGlobalContentData.value?.globalNavigationData?.['investor-relations'])
const footerNavigationData = computed(() => localeGlobalContentData.value?.globalFooterData?.['investor-relations'])

watchEffect(() => {
  if (LIGHT_THEME_PAGE.some((slug) => route.name.includes(slug))) {
    theme.value = 'light'
    return
  }
  if (GREY_THEME_PAGES.some((slug) => route.name.includes(slug))) {
    theme.value = 'grey'
    return
  }
  if (DARK_THEME_PAGES.some((slug) => route.name.includes(slug))) {
    theme.value = 'dark'
    return
  }
  theme.value = 'light'
})
</script>
